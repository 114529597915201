
export const checkPhone = (rule, value, callback) => {
    const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/;
    if (!value) {
        return callback(new Error("电话号码不能为空"));
    }
    if (!Number.isInteger(+value)) {
        callback(new Error("请输入数字值"));
    } else {
        if (phoneReg.test(value)) {
            callback();
        } else {
            callback(new Error("电话号码格式不正确"));
        }
    }
};
export const checkUser = (rule, value, callback) => {
    const name = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
    if (!value) {
        return callback(new Error("用户名不能为空"));
    }
    if (name.test(value)) {
        callback();
    } else {
        callback(new Error("用户名为6-20的字母和数字"));
    }
};
export const checkPassWord = (rule, value, callback) => {
    const name = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
    if (!value) {
        return callback(new Error("密码不能为空"));
    }
    if (name.test(value)) {
        callback();
    } else {
        callback(new Error("密码为6-20的字母和数字"));
    }
};

export const isEmail = (rule, value, callback) => {
    const reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
    if (!value) {
        return callback(new Error("请输入邮箱"));
    } else if (!reg.test(value)) {
        callback(new Error("输入邮箱格式不正确！"));
    } else {
        callback()
    }
};