//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Q from "q";
import _ from "underscore";
import registerText from "../component/register";

import userBuyer from "@/lib/data-service/default/web_common_userBuyer_new";
import getInlandAreaChildListByParentIdResponse from "@/lib/data-service/default/web_common_area_getInlandAreaChildListByParentIdResponse";
import kaptcha from "@/lib/data-service/default/web_common_kaptcha";
import phoneRegister from "@/lib/data-service/default/web_common_register";
import vueCheckUserName from "@/lib/data-service/default/web_common_user_vueCheckUserName";
import liability from "@/page/distributor/other/aboutUs/text/liability";
import knowledge from "@/page/distributor/other/aboutUs/text/knowledge";
import personage_register from "@/lib/data-service/default/web_common_personage_register";
import personageDetailByBuyerId from "@/lib/data-service/default/web_personage_personageDetailByBuyerId";
import resubmitForReview from "@/lib/data-service/default/web_personage_resubmitForReview";
import web_common_user_checkPhone from "@/lib/data-service/default/web_common_user_checkPhone";
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/get_user_info";

import { STATUS } from "@/lib/deprecated/utils/status";
import {
  checkPhone,
  checkUser,
  checkPassWord,
  isEmail,
} from "../component/rule";

const SUCCESS_CODE = STATUS.success;
export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.formData.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    const checkIdCard = (rule, value, callback) => {
      if (!this.formData.idCardFront) {
        callback(new Error("请上传机构照片"));
      }
      // else if (!this.formData.idCardReverse) {
      //   callback(new Error("请上传身份证反面"));
      // }
      else {
        callback();
      }
    };
    return {
      formData: {
        userName: "",
        password: "",
        phone: "",
        smsCode: "",
      },
      loading: false,
      accountType: 1, //1 2 供应商 ， 3 4 分销商 5 平台 6游客 7个人分销
      protocolText: {
        show: false,
        show1: false,
        text: liability,
        text1: knowledge,
        title: "网站声明",
        title1: "知识产权声明",
      },
      authCode: {
        url: "",
        value: "",
        show: false,
      },
      ossClient: new OssClient(),

      getCodePhone: {
        staut: false,
        show: false,
        disabled: true,
        num: 60,
        text: "获取短信验证码",
      },
      phoneStatu: false,
      agreeStatu: false,
      siteList: {}, //地址
      options: [],
      password: "",
      userType: "personage-register",
      checkCity: false,
      auditStatu: false,
      fileListImg: {
        idCardFront: [],
        idCardReverse: [],
      },
      imageUrl: {
        idCardFront: '',
      },
      uploadLoading: {
        idCardFront: false,
      },
      rules: {
        //验证
        userName: [
          // { validator: checkUser, trigger: "blur", required: true }
          {
            required: true,
            validator: checkUser,
            trigger: "blur",
          },
        ],
        password: [
          { validator: checkPassWord, trigger: "blur", required: true },
        ],
        passwordAgain: [
          { validator: validatePass, trigger: "blur", required: true },
        ],
        phone: [
          {
            validator: (rule, value, callback) => {
              const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/;
              if (!value) {
                callback(new Error("电话号码不能为空"));
              }
              if (!Number.isInteger(+value)) {
                callback(new Error("请输入数字值"));
              }
              if (!phoneReg.test(value)) {
                callback(new Error("电话号码格式不正确"));
              }
              if (this.phoneStatus === false) {
                callback(new Error("该手机号码已被注册"));
              }
              callback()
            },
            trigger: "blur",
            required: true,
          },
        ],
        smsCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        eMail: [{ required: true, validator: isEmail, trigger: "blur" }],
        idCard: [
          { required: true, message: "请输入机构号码", trigger: "blur" },
        ],
        checkIdCard: [
          { validator: checkIdCard, trigger: "blur", required: true },
        ],
        airLinkman: [
          { required: true, message: "请输入联系人", trigger: "blur" },
        ],
        areaCitys: [{ required: true, message: "请选择城市", trigger: "blur" }],
        realName: [
          { required: true, message: "请输入机构名称", trigger: "blur" },
        ],
      },
      phoneStatus: false, // 校验手机号码是否可用结果
    };
  },
  props: {
    personShow: Boolean,
  },
  created() {
    if (this.$route.query.type == 3) {
      this.auditStatu = true;
      this.getUserInfo();
    }
    this._getInlandArea("100000", (res) => {
      this.$set(this.siteList, "area", res);
    });
  },
  methods: {
    goRegister() {
      this.$router.push({ name: this.userType });
    },
    //获取产品图片上传的地址
    uploadChange(file, name) {
      this.$set(this.uploadLoading, name, true);
      this.ossClient
        .multipartUpload({
          file: file.file,
        })
        .then((res) => {
          this.formData[name] = res.url;
          this.$set(this.imageUrl, name, res.url);
          this.$message({ type: "success", message: "上传图片成功" });
          this.$refs["userForm"].validateField(name);
        })
        .catch(() => {
          this.$message.error('上传图片失败');
        })
        .finally(() => {
          this.$set(this.uploadLoading, name, false);
        });
    },
    //检验img格式
    detectionImg(file) {
      var reg2 = /^(\s|\S)+(jpg|png|bmp|jpeg)+$/;
      const isLt2M = file.size / 1024 < 10240;
      if (!reg2.test(file.name)) {
        this.$message({ type: "warning", message: "图片格式上传错误！" });
        return false;
      }
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 10M!");
        return false;
      }
    },
    //处理产品图片的删除
    handleRemoveImg(file, name) {
      this.formData[name] = "";
      this.fileListImg[name] = [];
      // this.$refs["userForm"].validateField(name);
      document.querySelector(`#${name} .el-upload`).style.display = "block";
    },
    //检查用户名
    queryUserName() {
      const name = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
      if (!this.formData.userName) {
        return;
      } else {
        if (!name.test(this.formData.userName)) return;
      }
      vueCheckUserName({ userName: this.formData.userName }).then((res) => {
        if (res.code === SUCCESS_CODE) {
          !res.flag
            ? this.$message({ type: "warning", message: "用户已注册" })
            : "";
        }
      });
    },
    //获取短信
    getNote() {
      let phoneReg = /^1[3|4|5|7|8][0-9]{9}$/;
      if (!this.formData.phone) {
        return this.$message({ type: "warning", message: "请输入手机号码" });
      } else {
        if (!phoneReg.test(this.formData.phone)) {
          return this.$message({
            type: "warning",
            message: "电话号码格式不正确",
          });
        }
      }
      this.authCode.value = "";
      this.authCode.show = true;
      kaptcha().then((res) => {
        if (res.code === SUCCESS_CODE) {
          this.authCode.url = res.img;
          this.authCode.uuid = res.uuid;
          this.$refs.authCode.focus();
        }
      });
    },
    getUserInfo() {
      // get_user_info().then(res => {
      //   this.accountType = res.userVo.userType;
      //   if (this.accountType == 1 || this.accountType == 2) {
      //     //供应
      //     this.getSupplier();
      //   } else if (
      //     //分销
      //     this.accountType == 3 ||
      //     this.accountType == 4 ||
      //     this.accountType == 7
      //   ) {
      //     this.getBuyer();
      //   }
      // });
      this.getBuyer();
    },
    getBuyer() {
      personageDetailByBuyerId().then((res) => {
        let _a = ["areaRegions", "areaProvinces", "areaCitys"];
        let data = {
          areaCity: res.personageDetailResult.areaCity,
          areaProvince: res.personageDetailResult.areaProvince,
          areaRegion: res.personageDetailResult.areaRegion,
          areaCityName: res.personageDetailResult.areaCityName,
          areaProvinceName: res.personageDetailResult.areaProvinceName,
          areaRegionName: res.personageDetailResult.areaRegionName,
          areaRegions: res.personageDetailResult.areaRegionName,
          areaProvinces: res.personageDetailResult.areaProvinceName,
          areaCitys: res.personageDetailResult.areaCityName,
          eMail: res.personageDetailResult.eMail,
          idCard: res.personageDetailResult.idCard,
          idCardFront: res.personageDetailResult.idCardFront,
          idCardReverse: res.personageDetailResult.idCardReverse, //身份照片更改
          phone: res.personageDetailResult.phone,
          realName: res.personageDetailResult.realName,
          userName: res.personageDetailResult.userName,
          failReason: res.personageDetailResult.failReason,
        };
        if (data.idCardFront && data.idCardReverse) {
          this.fileListImg["idCardFront"].push({
            name: "idCardFront",
            url: res.personageDetailResult.idCardFront,
          });
          this.fileListImg["idCardReverse"].push({
            name: "idCardReverse",
            url: res.personageDetailResult.idCardReverse,
          });
          document.querySelector(`#idCardFront .el-upload`).style.display =
            "none";

          // document.querySelector(`#idCardReverse .el-upload`).style.display =
          //   "none";
        }
        this.formData = data;
        this.$emit("getFailReason", data.failReason);
      });
    },
    // getSupplier() {
    //   supplierDetailBySupplierId().then(res => {
    //     let data = {
    //       areaCity: res.supplierDetailResult.areaCity,
    //       areaProvince: res.supplierDetailResult.areaProvince,
    //       areaRegion: res.supplierDetailResult.areaRegion,
    //       areaCityName: res.supplierDetailResult.areaCityName,
    //       areaProvinceName: res.supplierDetailResult.areaProvinceName,
    //       areaRegionName: res.supplierDetailResult.areaRegionName,
    //       areaRegions: res.supplierDetailResult.areaRegionName,
    //       areaProvinces: res.supplierDetailResult.areaProvinceName,
    //       areaCitys: res.supplierDetailResult.areaCityName,
    //       eMail: res.supplierDetailResult.airEmail,
    //       idCard: res.supplierDetailResult.idCard,
    //       idCardFront: res.supplierDetailResult.idCardFront,
    //       idCardReverse: res.supplierDetailResult.idCardReverse,
    //       phone: res.supplierDetailResult.airPhone,
    //       realName: res.supplierDetailResult.airLinkman,
    //       userName: res.supplierDetailResult.userName,
    //       failReason: res.supplierDetailResult.failReason
    //     };
    //     this.formData = data;
    //   });
    // },
    //手机验证码
    getPhoneAuthCode() {
      let authCode = this.authCode;
      if (authCode.value) {
        authCode.show = false;
        let data = {
          phoneNumber: this.formData.phone,
          verificationCode: authCode.value,
          roleType: 2,
          uuid: authCode.uuid,
        };
        this.getCodePhone.statu = true;
        this.getCodePhone.disabled = true;
        phoneRegister(data).then(
          (res) => {
            if (res.code === SUCCESS_CODE) {
              this.getCodePhone.statu = false;
              this.getCodePhone.show = true;
              this.getCodePhone.disabled = false;
              this.getCodePhone.text = "重新发送";
              let codeNmu = setInterval(() => {
                this.getCodePhone.num--;
                if (this.getCodePhone.num < 1) {
                  clearInterval(codeNmu);
                  this.getCodePhone.text = "获取短信验证码";
                  this.getCodePhone.show = false;
                  this.getCodePhone.disabled = false;
                  this.getCodePhone.num = 60;
                }
              }, 1000);
            }
          },
          (error) => {
            this.getCodePhone.statu = false;
            this.$forceUpdate();
          }
        );
      }
    },
    //提交表单
    async submitForm() {
      if (this.$route.query.type) {
        return this.againAudit();
      }
      let data = JSON.parse(JSON.stringify(this.formData));
      data.idCardReverse = data.idCardFront;
      let status = true;
      this.$refs["userForm"].validate((valid) => {
        if (valid) {
          if (!this.agreeStatu)
            return this.$message({
              type: "warning",
              message: "请勾选服务协议！",
            });
          this.selectCity(data);
          this.loading = true;
          data.idCard = data.idCard.replace(/\s+/g, "");
          personage_register(data)
            .then((res) => {
              this.$message({ type: "success", message: "提交成功！" });
              this.loading = false;
              this.$router.push({
                name: "register-success",
                query: { type: "4" },
              });
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          status = false;
          return false;
        }
      });

      if (status === false) {
        this.$nextTick(() => {
          let firstEle = document.getElementsByClassName(
            "el-form-item__error"
          )[0];
          document.documentElement.scrollTop =
            document.documentElement.scrollTop +
            firstEle.getBoundingClientRect().top -
            firstEle.parentElement.offsetHeight -
            18;
        });
      }
    },
    selectCity(data) {
      let delList = [
        "areaRegions",
        "areaProvinces",
        "areaCitys",
        "passwordAgain",
      ];
      let _a = ["areaRegions", "areaProvinces", "areaCitys"];
      let _b = ["areaRegion", "areaProvince", "areaCity"];
      let nameArr = ["areaRegionName", "areaProvinceName", "areaCityName"];
      nameArr.map((item, index) => {
        data[item] = data[_a[index]][1];
        data[_b[index]] = data[_a[index]][0];
      });
      delList.map((item) => {
        delete data[item];
      });
    },
    againAudit() {
      let data = JSON.parse(JSON.stringify(this.formData));
      if (this.checkCity) {
        this.selectCity(data);
      }
      this.$refs["userForm"].validate((valid) => {
        if (valid) {
          if (!this.agreeStatu)
            return this.$message({
              type: "warning",
              message: "请勾选服务协议！",
            });
          resubmitForReview(data)
            .then((res) => {
              this.$message({ type: "success", message: "提交成功！" });
              this.loading = false;
              this.$router.push({
                name: "register-success",
                query: { type: "4" },
              });
            })
            .catch(() => {
              this.loading = false;
            });
        } else {
          return false;
        }
      });
    },
    //获取城市列表
    _getInlandArea(id, callback) {
      getInlandAreaChildListByParentIdResponse({ id: id }).then((res) => {
        if (res.code === SUCCESS_CODE) {
          callback(res.areaTreeResult);
        }
      });
    },
    //选中大区
    _getArea(val) {
      this.checkCity = true;
      this._getInlandArea(val[0], (res) => {
        this.formData.areaProvinces ? (this.formData.areaProvinces = "") : "";
        this.formData.areaCitys ? (this.formData.areaCitys = "") : "";
        this.$set(this.siteList, "province", res);
      });
    },
    //选中省份
    _getProvince(val) {
      this._getInlandArea(val[0], (res) => {
        this.formData.areaCitys ? (this.formData.areaCitys = "") : "";
        this.$nextTick(() => {
          this.$set(this.siteList, "city", res);
        });
      });
    },
    // 检查电话号码是否可以注册
    checkPhoneEffective: _.debounce(function () {
      if (!/^1[0-9]{10}$/.test(this.formData.phone)) {
        this.getCodePhone.disabled = true;
        return false;
      }
      web_common_user_checkPhone({ phone: this.formData.phone, userType: 1 }).then(
        (data) => {
          this.phoneStatus = data;
          this.$refs.userForm.validateField('phone')
          if (data) {
            this.getCodePhone.disabled = false;
          } else {
            this.getCodePhone.disabled = true;
          }
        }
      );
    }, 300),
  },
};
