const __request = require(`@/lib/data-service/default/__request/__request_contentType_json`);
/**
 * 个人注册
 */
export default function (val) {
    var pParameter = {}
    pParameter.method = "POST"
    pParameter.urlSuffix = "/web/common/personage/register"
    pParameter.data = val
    pParameter.check_token = false;
    return new Promise(function (resolve, reject) {
        resolve(__request(pParameter))
    })
}
